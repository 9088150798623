import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import {HashRouter} from "react-router-dom";
import "./assets/base.scss";
import Main from "./www/Main";
import configureStore from "./config/configureStore";
import {Provider} from "react-redux";
import {Bounce, toast} from "react-toastify";
// import {default as NextApp} from "./DemoPages/Main";

const store = configureStore();
const rootElement = document.getElementById("root");

// global.ipServer = "https://aquaneo.com.my/be/"
// global.ipServer = "http://localhost:8080/"
if (window.location.hostname === "aquaneo.com.my") {
    global.ipServer = "https://aquaneo.com.my/be/"
}else {
    global.ipServer = "http://localhost:8080/"
}


global.user_id = localStorage.getItem('user_id')
global.user_name = localStorage.getItem('user_name')
global.user_role = localStorage.getItem('user_role')

export const toastFunc = (msg, type) => {
    toast(msg, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
        type: type
    });
}

const renderApp = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component/>
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept("./www/Main", () => {
        const NextApp = require("./www/Main").default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
