import {Link, Redirect, Route} from "react-router-dom";
import React, {Fragment, lazy, Suspense} from "react";
import Loader from "react-loaders";

import {ToastContainer} from "react-toastify";
import {redirectLogout} from '../../index'
import {Col} from "reactstrap";
// import {BudgetApprovalNav} from "../AppNav/NavItems";
const UM = lazy(() => import("../../www/UserManagement/index"));
const Login = lazy(() => import("../../www/Login/index"));
const POS = lazy(() => import("../../www/POS/index"));
const Attendance = lazy(() => import("../../www/Attendance/index"));
const Sales = lazy(() => import("../../www/Sales/index"));
const Promo = lazy(() => import("../../www/Promo_Voucher/index"));
const Pakej = lazy(() => import("../../www/Pakej/index"));

const URL = window.location.href.includes("300") || window.location.origin.includes("asus")

export const setAuth = {

    isAuthenticated: true,


    authenticate(data, h) {
        try {
            if (this.isAuthenticated) {
                localStorage.clear()  //clear untuk unauthorized
                //
                // if(Array.isArray(data)){
                //     data.map((e, i ) => {
                //         if(i === 0){
                //             localStorage.setItem('user_id', e.id);
                //             localStorage.setItem('user_role', e.role);
                //         }else{
                //             localStorage.setItem(`user_id_${i}`, e.id);
                //             localStorage.setItem(`user_role_${i}`, e.role);
                //         }
                //
                //         h.push('/um/multiple');
                //
                //
                //     })
                // }else{
                    localStorage.setItem('user_id', data.id);
                    localStorage.setItem('user_role', data.role);
                    localStorage.setItem('user_name', data.name);
                    h.push('/um/profile');
                // }

                // h.push('/um/profile');
                window['location']['reload']()

            }

        } catch (error) {
            console.log(error)
            // window['location']['reload']()
            // redirectLogout()
        }


    },

    signout(cb) {
        // this.isAuthenticated = null

        localStorage.clear()
    }
}

const PrivateRoute = ({component: Component, ...rest}) => {
    return (URL === true ? <Route {...rest} render={(props) => (
            localStorage.getItem("user_id") !== null
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}/>
        )}/>
        : <Route {...rest} render={(props) => (
            localStorage.getItem("user_id") !== null
                ? <Component {...props} />
                : <Redirect to='/login'/>
        )}/>)
};

const AppMain = () => {

    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/um" component={UM}/>
            </Suspense>

            {/* POS */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/pos" component={POS}/>
            </Suspense>

            {/* Attendance */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/attendance" component={Attendance}/>
            </Suspense>

            {/* Sales */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/sales" component={Sales}/>
            </Suspense>

            {/* Promo */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/promo" component={Promo}/>
            </Suspense>

            {/* Pakej */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/pakej" component={Pakej}/>
            </Suspense>

            {/* Login */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale-party"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Pages examples
                            <small>Because this is a demonstration we load at once all the Pages examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/login" component={Login}/>
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/login/main"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
